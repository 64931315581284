export interface Char {
  id: number
  name: string
  status: string
  species: string
  type: string
  gender: string
  image: string
}

interface Props extends Char {
  onClick: () => void
}

export default function Character(props: Props) {
  return (
    <div style={{
      display: 'inline-block',
      maxWidth: '20%',
      margin: 10,
      overflow: "hidden",
      cursor: 'pointer',
      height: 400
    }} onClick={props.onClick}>
      <h3 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.name}</h3>
      <img alt={props.name} src={props.image}/>
    </div>
  )
}