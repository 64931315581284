import React from 'react';
import './App.css';
import Characters from './Characters'
import {Char} from './Character'

function App() {
  const [character, setSelectedCharacter] = React.useState<Char>()
  return (
    <div className="App">
      <header className="App-header" style={{paddingLeft: 400}}>
        <div style={{
          display: 'flex',
          flexDirection: "column",
          height: "100%",
          width: 400,
          justifyContent: 'center',
          backgroundColor: '#282c34',
          borderBottom: '1px solid #192336',
          boxShadow: '1px 0px 30px rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          left: 0,
          top: 0,
          alignItems: 'flex-start',
          padding: 30
        }}>
          {!character && <p>Select character to see details</p>}
          {character &&
          <>
            <div style={{
              flexDirection: "column",
              flex: 1,
              textAlign: "right",
            }}>
              <img alt={character.name} src={character.image}/>
            </div>
            <div style={{
              flexDirection: "column",
              flex: 1,
              textAlign: "left",
              padding: 10
            }}>
              <h2>{character.name}</h2>
              <p>Status: {character.status}</p>
              <p>Species: {character.species}</p>
              <p>Type: {character.type}</p>
              <p>Gender: {character.gender}</p>
            </div>
            <div style={{
              flexDirection: "column",
              flex: 1,
              textAlign: "left",
              paddingLeft: 10,
              paddingTop: 30
            }}>
              <button onClick={() => setSelectedCharacter(undefined)} style={{
                padding: 10,
                borderRadius: 4,
                border: 'none',
                outline: "none",
                fontWeight: 700,
                backgroundColor: 'salmon'
              }}>unselect
              </button>
            </div>
          </>}
        </div>
        <Characters onSelectCharacter={setSelectedCharacter}/>
      </header>
    </div>
  );
}

export default App;
