import React from 'react'
import Character, {Char} from "./Character";

type CharactersProps = {
  onSelectCharacter: (character: Char) => void
}

export default function Characters(props: CharactersProps) {
  const [loadedCharacters, setLoadedCharacters] = React.useState<Char[]>([])

  React.useEffect(() => {
    const apiUrl = "https://rickandmortyapi.com/api/character"
    fetch(apiUrl, {
      headers: {
        'Accept': 'application/json'
      }
    }).then(result => result.json()).then(json => setLoadedCharacters(json.results))
  }, [])

  return (<div>
    <h1>Characters</h1>
    {loadedCharacters.map(character => <Character onClick={() => props.onSelectCharacter(character)} {...character} />)}
  </div>)
}